import React from "react"
import tw, { styled } from "twin.macro"
import Img from "gatsby-image"
import { GenerateButton } from "../utils/generateLinks"
import BlockContent from "@sanity/block-content-to-react"
import { Carousel } from "./ImageCarousel"

let Container = tw.section`
  container  py-12 lg:py-32 flex justify-between md:flex-row flex-col
`

let ContentArea = tw.div`
md:w-1/2 text-primary mb-16 md:mb-0
`

let ImageContainer = styled.div`
  ${tw`object-cover object-center relative md:m-0 mx-auto`};
  margin-top: 5%;
  width: 80%;
  height: 80%;

  @media screen and (min-width: 768px) {
    width: 40%;
    height: 40%;
  }

  :after {
    ${tw`absolute border-primary border border-2 z-10`};
    content: "";
    width: 100%;
    height: 100%;
    top: -5%;
    left: 5%;
    z-index: 10;
  }
`

let Image = styled(Img)`
  ${tw` h-full z-30`};
  display: block;
`

let P = tw.p`
    mb-2 font-sans text-primary
`
const H2 = tw.h2`text-4xl font-sans font-bold pb-6`

const serializer = {
  types: {
    block(props) {
      switch (props.node.style) {
        default:
          return <P>{props.children}</P>
      }
    },
  },
}

export let ContentBlock = props => {
  const { button, images, title, _rawCopy } = props

  const showGallery = images.length > 1


  return (
    <Container>
      <ContentArea>
        <H2>{title}</H2>
        <BlockContent blocks={_rawCopy} serializers={serializer} />
        {button && (
          <GenerateButton link={button.link}> {button.label} </GenerateButton>
        )}
      </ContentArea>
      <ImageContainer>
        {showGallery ? <Carousel images={images} /> : <Image fluid={{ ...images[0].asset.fluid, aspectRatio: 1 }} />}
      </ImageContainer>
    </Container>
  )
}
