import React from "react"
import tw, { styled } from "twin.macro"
import Img from "gatsby-image"
import { GenerateButton } from "../utils/generateLinks"
import BlockContent from "@sanity/block-content-to-react"

const ServiceWrapper = styled.section`
  ${tw`flex text-white flex-col`};
  ${p =>
    p.orientation === "img-left" ? tw`md:flex-row-reverse` : tw`md:flex-row`};
  background: ${p => p.bg};
`

const ServiceContent = tw.div`
text-white  w-full self-center justify-center content-center
`

const H2 = tw.h2`text-4xl font-sans font-bold pb-6`

const ServiceContentWrapper = tw.div`
    md:w-1/2 w-full  py-16 px-12 md:px-16 flex items-center justify-center
`

const ServiceImageWrapper = tw.div`
   md:w-1/2 w-full overflow-hidden object-center object-cover flex-1
`

const Image = styled(Img)`
  ${tw`h-64 md:h-full`};

  max-height: 400px;
`

let P = tw.p`
    mb-2 font-sans text-primary text-white
`

let Content = styled(BlockContent)`
  ${tw`text-white font-sans`};
`

const CTA = ({ label, link }) => (
  <GenerateButton link={link} theme={"dark"}>
    {label}
  </GenerateButton>
)

const serializer = {
  types: {
    block(props) {
      switch (props.node.style) {
        default:
          return <P>{props.children}</P>
      }
    },
  },
}

export let ServiceBlock = ({
  title,
  backgroundColour,
  imageOrientation,
  image,
  _rawCopy,
  button: { label, link },
}) => {
  return (
    <ServiceWrapper orientation={imageOrientation} bg={backgroundColour}>
      <ServiceContentWrapper>
        <ServiceContent>
          <H2>{title}</H2>
          <Content
            blocks={_rawCopy}
            serializers={serializer}
            backgroundColour={backgroundColour}
          />
          <CTA label={label} link={link} />
        </ServiceContent>
      </ServiceContentWrapper>
      <ServiceImageWrapper>
        <Image fluid={image.asset.fluid} />
      </ServiceImageWrapper>
    </ServiceWrapper>
  )
}
