import React from "react"
import tw, { styled } from "twin.macro"
import BlockContent from "@sanity/block-content-to-react"

let ContactMethod = tw.div`
max-w-full py-8 px-2 font-sans shadow lg:shadow-xl bg-white flex flex-col items-center text-center
`

let FullWidthContactMethod = tw(ContactMethod)`
   col-span-full 
`

let Container = tw.section`
  container -mt-20 py-16 text-primary font-sans
`

let ContactMethods = tw.div`
 grid lg:grid-cols-2 gap-4
`

let P = styled.p`
  ${tw`text-blue-800`}

  strong {
    ${tw`font-sans text-primary leading-tight text-lg font-bold`}
  }
`

let H3 = tw.h3`
  text-lg mb-4 font-bold font-sans text-primary leading-tight
`

const serializer = {
  types: {
    block(props) {
      switch (props.node.style) {
        case "h3":
          return <H3>{props.children}</H3>
        default:
          return <P>{props.children}</P>
      }
    },
  },
}

export let ContactBlock = props => {
  let {
    contactMethods,
    _rawWriteToUs,
    address1,
    address2,
    city,
    county,
    country,
    name,
    postcode,
  } = props
  return (
    <Container>
      <ContactMethods>
        {contactMethods.map(method => (
          <ContactMethod key={method._key}>
            <BlockContent blocks={method._rawCopy} serializers={serializer} />
          </ContactMethod>
        ))}
        <FullWidthContactMethod>
          <div>
            <H3>Write to us</H3>
            <BlockContent blocks={_rawWriteToUs} serializers={serializer} />
          </div>
          <div>
            <P>{name}</P>
            <P>{address1}</P>
            <P>{address2}</P>
            <P>{city}</P>
            <P>{county}</P>
            <P>{country}</P>
            <P>{postcode}</P>
          </div>
        </FullWidthContactMethod>
      </ContactMethods>
    </Container>
  )
}
