import React from "react"
import ReactCompareImage from "react-compare-image"
import tw, { styled } from "twin.macro"

const Container = tw.section`
container py-16
`
const Compare = styled.div`
  ${tw`md:w-2/3 mx-auto z-20 text-primary text-center font-sans relative pb-32`};
`

const Background = styled.div`
  ${tw`bg-secondary`};
  position: absolute;
  bottom: -5%;
  left: -3%;
  width: 100%;
  height: 100%;
  z-index: -1;
`

let Slider = styled(ReactCompareImage)`
  ${tw`shadow-2xl`};
`

const H2 = tw.h2`text-4xl font-bold pb-2`

let P = tw.p`
    mb-2
`

const Img = styled.div`
  ${tw`relative z-30 h-full w-full my-8 shadow-2xl`}
`

export let CompareBlock = props => (
  <Container>
    <Compare>
      <H2>{props.title}</H2>
      <P>{props.tagline}</P>
      <Img>
        <Slider
          leftImage={props.image1.asset.fluid.src}
          rightImage={props.image2.asset.fluid.src}
        />
        <Background />
      </Img>
    </Compare>
  </Container>
)
