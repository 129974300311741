import React from "react"
import tw, { styled } from "twin.macro"
import Img from "gatsby-image"
import { GenerateButton } from "../utils/generateLinks"

let Container = tw.section`
container lg:py-16 py-8 text-center
`

const H2 = tw.h2`text-4xl font-sans font-bold pb-6 text-primary`

let Tagline = tw.p`pb-12 font-sans text-gray-900 text-lg`

let NewsCardWrapper = styled.div`
  ${tw`shadow-xl inline-flex flex-col w-full lg:w-1/3 mb-8 lg:mb-0 overflow-hidden rounded-md mr-6 `};
`

let NewsCardContent = styled.div`
  ${tw` py-8 px-6 justify-between flex flex-wrap flex-auto`};

  & > * {
    flex: 1 1 100%;
  }
`

let NewsCardTitle = styled.h3`
  ${tw`text-xl font-sans text-primary font-bold inline-block text-left pb-4 leading-none`};
`

let NewsCardTagline = styled.p`
  ${tw`text-left text-gray-900 pb-4 font-sans text-base `};
`

let NewsCards = styled.div`
  ${tw`flex flex-col lg:flex-row`};
  justify-content: center;
`

const NewsCard = props => (
  <NewsCardWrapper count={props.count}>
    {props.image.asset.fluid ? <Img fluid={props.image.asset.fluid} /> : null}
    <NewsCardContent>
      <NewsCardTitle>{props.title}</NewsCardTitle>
      <NewsCardTagline>{props.tagline}</NewsCardTagline>
      <GenerateButton
        link={props.linkOveride ? props.link : `/news/${props.slug.current}`}
      >
        Read more
      </GenerateButton>
    </NewsCardContent>
  </NewsCardWrapper>
)

export let NewsBlock = ({ title, tagline, news, featured }) => {
  return (
    <Container>
      <H2>{title}</H2>
      <Tagline>{tagline}</Tagline>
      <NewsCards>
        <NewsCard {...featured} linkOveride={featured.link} key={"featured"} />
        {news.nodes.map((news, i) => (
          <NewsCard {...news} key={news._id} count={i} />
        ))}
      </NewsCards>
    </Container>
  )
}
