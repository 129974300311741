import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { Hero } from "../components/hero"
import { ContentBlock } from "../components/ContentBlock"
import { ServiceBlock } from "../components/ServiceBlock"
import { CompareBlock } from "../components/Compare"
import { ContactBlock } from "../components/ContactBlock"
import { NewsBlock } from "../components/News"
import { AttachmentsBlock } from "../components/AttachmentsBlock"

export const query = graphql`
  query PageQuery($slug: String!, $limit: Int!) {
    sanityCompany {
      address1
      address2
      city
      county
      country
      email
      name
      phone
      postcode
    }
    allSanityNews(limit: $limit, sort: { fields: priority, order: ASC }) {
      nodes {
        slug {
          current
        }
        title
        tagline
        _id
        _createdAt(formatString: "dddd MMMM Do, YYYY hh:mma")
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    sanityPage(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      hero {
        _key
        headline
        tagline
        background {
          asset {
            url
            localFile {
              childImageSharp {
                fluid(cropFocus: CENTER, grayscale: true, maxWidth: 2000) {
                  src
                }
              }
            }
          }
        }
      }
      content {
        ... on SanityContactBlock {
          _key
          _type
          _rawWriteToUs
          contactMethods {
            _rawCopy
          }
        }

        ... on SanityContentBlock {
          _key
          _type
          _rawCopy
          title
          imageOrientation
          button {
            link
            label
          }
          images {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        ... on SanityComparisonBlock {
          _key
          _type
          title
          tagline
          image1 {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          image2 {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        ... on SanityServiceBlock {
          _key
          _type
          title
          _rawCopy
          button {
            link
            label
          }
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          backgroundColour
          imageOrientation
        }
        ... on SanityNewsBlock {
          _key
          _type
          title
          tagline
          featured {
            link
            tagline
            title
            image {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

let Page = ({ data }) => {
  const { sanityPage, sanityCompany, allSanityNews } = data
  return (
    <Layout>
      {sanityPage.title && (
        <SEO
          title={sanityPage.title}
          image={sanityPage.hero.background.asset.url}
        />
      )}
      <Hero key={sanityPage?.hero?.key || "hero"} {...sanityPage?.hero} />
      {sanityPage?.content?.map(block => {
        if (block._type === "contentBlock") {
          return <ContentBlock {...block} key={block._key} />
        }
        if (block._type === "serviceBlock") {
          return <ServiceBlock {...block} key={block._key} />
        }
        if (block._type === "comparisonBlock") {
          return <CompareBlock {...block} key={block._key} />
        }
        if (block._type === "contactBlock") {
          return <ContactBlock {...sanityCompany} {...block} key={block._key} />
        }
        if (block._type === "attachmentsBlock") {
          return <AttachmentsBlock {...block} key={block._key} />
        }
        if (block._type === "newsBlock") {
          return <NewsBlock {...block} news={allSanityNews} key={block._key} />
        }
        return null
      })}
    </Layout>
  )
}

export default Page
